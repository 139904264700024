<template>
    <div class="clearfix">
        <a-upload :data="ParamData"
                  action="/FileServer/Upload"
                  @change="handleChange">
            <a-button> <a-icon type="upload" /> 图片上传 </a-button>
        </a-upload>
        <a-upload :data="ParamData2"
                  action="/FileServer/Upload"
                  @change="handleChange">
            <a-button> <a-icon type="upload" /> Excel上传 </a-button>
        </a-upload>
    </div>
</template>
<script>
    export default {
        name: "UploadDemo",
        data() {
            return {
                ParamData: {
                    ProcessorName: "Image",
                    JsonDataParams: ""
                },
                ParamData2: {
                    ProcessorName: "exceltest",
                    JsonDataParams: ""
                },
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            handleChange(info) {
                console.log(info);
                if (info.file.status !== 'uploading') {
                    this.$message.success(`正在上传文件${info.file.name}`);
                }
                if (info.file.status === 'done') {
                    this.$message.success(`${info.file.name} 文件上传成功`);
                    //console.log(info.file.response);
                } else if (info.file.status === 'error') {
                    if (info.file.response && info.file.response.Message) {
                        this.$message.error(info.file.response.Message);
                    }
                    else {
                        this.$message.error(`${info.file.name} 文件上传失败`);
                    }
                }
            },
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>